import axios from 'axios';

const BASE_URL = 'https://api.isendxpress.com/api/coupons'; // Replace with your API base URL

// Helper function to get token from localStorage
const getAuthToken = () => {
  const token = localStorage.getItem('auth_token');
  return token ? `Bearer ${token}` : '';
};

const couponService = {
  getAllCoupons: async (filters = {}) => {
    try {
      const response = await axios.get(BASE_URL, {
        params: filters,
        headers: { Authorization: getAuthToken() },
      });
      console.log(response.data)
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  },

  getCouponById: async (id) => {
    try {
      const response = await axios.get(`${BASE_URL}/${id}`, {
        headers: { Authorization: getAuthToken() },
      });
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  },

  createCoupon: async (data) => {
    try {
      const response = await axios.post(BASE_URL, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: getAuthToken(),
        },
      });
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  },

  updateCoupon: async (id, data) => {
    try {
      const response = await axios.put(`${BASE_URL}/${id}`, data, {
        headers: { Authorization: getAuthToken() },
      });
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  },

  deleteCoupon: async (id) => {
    try {
      const response = await axios.delete(`${BASE_URL}/${id}`, {
        headers: { Authorization: getAuthToken() },
      });
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  },

  validateCoupon: async (code) => {
    try {
      const response = await axios.get(`${BASE_URL}/validate`, {
        params: { code },
        headers: { Authorization: getAuthToken() },
      });
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  },

  applyCoupon: async (code, userId) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/apply`,
        { code, user_id: userId },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: getAuthToken(),
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  },
};

export default couponService;
