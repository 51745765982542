import React, { useEffect, useState } from 'react';
import { FiSearch, FiEdit, FiTrash2, FiEye } from 'react-icons/fi';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import userService from '../services/userService';

const Users = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      fetchUsers();
    }, 300);

    return () => clearTimeout(debounceTimer);
  }, [searchQuery, selectedRole, currentPage]);

  const fetchUsers = async () => {
    const filters = {
      search: searchQuery,
      role: selectedRole,
      page: currentPage,  // Include page in the request
    };

    try {
      const response = await userService.getAllUsers(filters);
      setUsers(response.data.data);
      setTotalPages(response.data.last_page);
    } catch (error) {
      console.error('Failed to fetch users:', error);
    }
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to page 1 when searching
  };

  const handleRoleFilter = (e) => {
    setSelectedRole(e.target.value);
    setCurrentPage(1); // Reset to page 1 when filtering
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <div className="flex flex-1">
        <Sidebar isOpen={isSidebarOpen} />
        <div className={`flex-1 ${isSidebarOpen ? 'pl-64' : 'pl-16'} transition-all`}>
          <Header toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} />

          <main className="p-6">
            <h1 className="text-3xl font-semibold text-gray-700 mb-6">Users</h1>

            <div className="flex items-center mb-6 space-x-4">
              <div className="relative w-full md:w-1/3">
                <input
                  type="text"
                  className="w-full pl-10 pr-4 py-2 border border-blue-400 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white"
                  placeholder="Search Users"
                  value={searchQuery}
                  onChange={handleSearch}
                />
                <FiSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" size={18} />
              </div>

              <div className="relative w-full md:w-1/4">
                <select
                  className="w-full py-2 pl-3 pr-10 border border-blue-400 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white"
                  value={selectedRole}
                  onChange={handleRoleFilter}
                >
                  <option value="">All Roles</option>
                  <option value="Admin">Admin</option>
                  <option value="User">User</option>
                  <option value="Tutor">Tutor</option>
                </select>
              </div>
            </div>

            <div className="overflow-x-auto bg-white shadow-lg rounded-lg">
              <table className="w-full table-auto">
                <thead className="bg-blue-50 text-blue-600">
                  <tr>
                    <th className="py-3 px-6 text-left">Name</th>
                    <th className="py-3 px-6 text-left">Email</th>
                    <th className="py-3 px-6 text-left">Role</th>
                    <th className="py-3 px-6 text-left"># Requests</th>
                    <th className="py-3 px-6 text-center">Actions</th>
                  </tr>
                </thead>
                <tbody className="text-gray-700">
                  {users.map((user, index) => (
                    <tr
                      key={user.id}
                      className={`border-t ${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}`}
                    >
                      <td className="py-3 px-6">{user.name}</td>
                      <td className="py-3 px-6">{user.email}</td>
                      <td className="py-3 px-6">{user.roles.map((role) => role.name).join(', ')}</td>
                      <td className="py-3 px-6">{user.pickup_requests.length}</td>
                      <td className="py-3 px-6 text-center">
                        <button className="text-blue-500 hover:text-blue-700 mr-2">
                          <FiEye size={18} />
                        </button>
                        <button className="text-yellow-500 hover:text-yellow-700 mr-2">
                          <FiEdit size={18} />
                        </button>
                        <button className="text-red-500 hover:text-red-700">
                          <FiTrash2 size={18} />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Pagination Controls */}
            <div className="flex justify-center items-center mt-6 space-x-4">
              <button
                className={`px-4 py-2 bg-blue-500 text-white rounded-lg ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-700'}`}
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <span className="text-gray-700">
                Page {currentPage} of {totalPages}
              </span>
              <button
                className={`px-4 py-2 bg-blue-500 text-white rounded-lg ${currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-700'}`}
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          </main>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Users;
