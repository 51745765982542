import React, { useState, useEffect } from "react";
import {
  ClockIcon,
  CheckCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import requestService from "../services/requestService";
import categoryService from "../services/CategoryService";
import { VscLoading } from "react-icons/vsc";
import RequestDetailsModal from "./RequestDetailsModal";

const PickupRequestsList = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);
  const [pickupRequests, setPickupRequests] = useState([]);
  const [statistics, setStatistics] = useState({});
  const [totalPages, setTotalPages] = useState(1);
  const [categories, setCategories] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const itemsPerPage = 5;

  const fetchPickupRequests = async () => {
    setLoading(true);
    try {
      const filters = {
        search: searchQuery,
        category: selectedCategory === "All" ? null : selectedCategory,
        page: currentPage,
        perPage: itemsPerPage,
        from_date: fromDate,
        to_date: toDate,
      };

      const response = await requestService.getAllRequests(filters);
      const { pickupRequests, statistics: stats } = response.data;

      setPickupRequests(pickupRequests.data);
      setStatistics(stats);
      setTotalPages(Math.ceil(stats.total_requests / itemsPerPage));
    } catch (error) {
      console.error("Failed to fetch pickup requests:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await categoryService.getCategories();
      setCategories(response.data);
    } catch (error) {
      console.error("Failed to fetch categories:", error);
    }
  };

  useEffect(() => {
    fetchPickupRequests();
    fetchCategories();
  }, [searchQuery, selectedCategory, currentPage, fromDate, toDate]);

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      fetchPickupRequests();
    }, 500);
    return () => clearTimeout(debounceTimer);
  }, [searchQuery]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleRequestClick = (request) => {
    setSelectedRequest(request);
  };

  const closePopup = () => {
    setSelectedRequest(null);
  };

  const handleDateChange = () => {
    setCurrentPage(1);
    fetchPickupRequests();
  };

  return (
    <div className="p-6 min-h-screen">
      <div className="flex items-center gap-6 mb-8 border-b pb-4">
        <input
          type="text"
          placeholder="Search requests..."
          value={searchQuery}
          onChange={handleSearchChange}
          className="border p-2 rounded-lg w-full sm:w-1/3 shadow-md focus:ring-2 focus:ring-indigo-500"
        />
        <select
          value={selectedCategory}
          onChange={handleCategoryChange}
          className="border p-2 rounded-lg shadow-md w-full sm:w-1/4 focus:ring-2 focus:ring-indigo-500"
        >
          <option value="All">All Categories</option>
          {categories.map((category) => (
            <option key={category.id} value={category.name}>
              {category.name}
            </option>
          ))}
        </select>

        <div className="flex gap-10 w-full sm:w-auto">
          <input
            type="date"
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
            className="border p-2 rounded-lg shadow-md w-full sm:w-1/4 focus:ring-2 focus:ring-indigo-500"
            placeholder="From"
          />
          <input
            type="date"
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
            className="border p-2 rounded-lg shadow-md w-full sm:w-1/4 focus:ring-2 focus:ring-indigo-500"
            placeholder="To"
          />
          <button
            onClick={handleDateChange}
            className="px-4 py-2 bg-blue-900 text-white rounded-lg"
          >
            Apply Date Filter
          </button>
        </div>
      </div>

      <div className="grid grid-cols-3 gap-6 mb-8">
        <div className="p-4 bg-blue-50 rounded-lg shadow-lg">
          <p className="text-blue-700">Total Requests</p>
          <h2 className="text-2xl font-bold text-blue-600">
            {statistics.total_requests || 0}
          </h2>
        </div>
        <div className="p-4 bg-yellow-50 rounded-lg shadow-lg">
          <p className="text-yellow-700">Pending</p>
          <h2 className="text-2xl font-bold text-yellow-600">
            {statistics.pending || 0}
          </h2>
        </div>
        <div className="p-4 bg-green-50 rounded-lg shadow-lg">
          <p className="text-green-700">Delivered</p>
          <h2 className="text-2xl font-bold text-green-600">
            {statistics.delivered || 0}
          </h2>
        </div>
      </div>

      {loading && (
        <div className="absolute inset-0 flex justify-center items-center bg-black bg-opacity-50">
          <VscLoading className="w-16 h-16 text-indigo-500 animate-spin" />
        </div>
      )}

      <table className="w-full bg-white rounded-lg shadow-lg overflow-hidden">
        <thead className="bg-indigo-100">
          <tr>
            <th className="text-left p-4 text-indigo-600">Sender</th>
            <th className="text-left p-4 text-indigo-600">Item</th>
            <th className="text-left p-4 text-indigo-600">Recipient</th>
            <th className="text-left p-4 text-indigo-600">From</th>
            <th className="text-left p-4 text-indigo-600">To</th>
            <th className="text-left p-4 text-indigo-600">Date</th>
            <th className="text-left p-4 text-indigo-600">Status</th>
          </tr>
        </thead>
        <tbody>
          {pickupRequests.map((request) => (
            <tr
              key={request.id}
              className="hover:bg-indigo-50 cursor-pointer border-b border-blue-900"
              onClick={() => handleRequestClick(request)}
            >
              <td className="p-4">{request.sender_name}</td>
              <td className="p-4">{request.item_name}</td>
              <td className="p-4">{request.recipient_name}</td>
              <td className="p-4">{request.from_address}</td>
              <td className="p-4">{request.to_address}</td>
              <td className="p-4">
                <td className="p-4">
                  {new Date(request.created_at).toLocaleString()}
                </td>
              </td>
              <td className="p-4">
                <span
                  className={`inline-flex items-center p-2 rounded-lg ${
                    request.delivery_status === "pending"
                      ? "bg-yellow-200 text-yellow-600"
                      : request.delivery_status === "delivered"
                      ? "bg-green-200 text-green-600"
                      : "bg-gray-200 text-gray-600"
                  }`}
                >
                  {request.delivery_status === "Pending" && (
                    <ClockIcon className="w-4 h-4 mr-2" />
                  )}
                  {request.delivery_status === "Delivered" && (
                    <CheckCircleIcon className="w-4 h-4 mr-2" />
                  )}
                  {request.delivery_status === "Cancelled" && (
                    <XCircleIcon className="w-4 h-4 mr-2" />
                  )}
                  {request.delivery_status}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="flex justify-between items-center mt-4">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="p-2 bg-indigo-500 text-white rounded-lg"
        >
          Previous
        </button>
        <p className="text-indigo-600">
          Page {currentPage} of {totalPages}
        </p>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="p-2 bg-indigo-500 text-white rounded-lg"
        >
          Next
        </button>
      </div>

      {selectedRequest && (
        <RequestDetailsModal request={selectedRequest} onClose={closePopup} />
      )}
    </div>
  );
};

export default PickupRequestsList;
