import React, { useState, useEffect } from 'react';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import Footer from '../components/Footer';
import DashboardWidgets from '../components/DashboardWidgets';
import BarChart from '../components/Charts/BarChart';
import LineChart from '../components/Charts/LineChart';
import RecentRequests from './Requests';
import RecentTransactions from './Transactions';
import ActivityLog from '../components/ActivityLog';
import UserGrowthChart from '../components/Charts/UserGrowthChart';
import ServerStatus from '../components/ServerStatus';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Dashboard = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [user, setUser] = useState(null);
  const [userAvatar, setUserAvatar] = useState('');

  useEffect(() => {
    // Fetch the user data from localStorage if it's set during login
    const storedUser = localStorage.getItem('user');

    //parse as json
    const parsedUser = JSON.parse(storedUser);
    
    if (storedUser) {
      setUserAvatar('https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y');
      setUser(parsedUser)
    } 
  }, []);

  useEffect(() => {
    AOS.init({ duration: 1000, easing: 'ease-in-out', once: true });
    
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <div className="flex flex-1">
        <Sidebar isOpen={isSidebarOpen} />
        <div className={`flex-1 ${isSidebarOpen ? 'pl-64' : 'pl-16'} transition-all`}>
          <Header toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} />
          <main className="p-6">
            {/* User Profile Section */}
            <section className="bg-white shadow-lg rounded-lg p-6 mb-8 flex items-center space-x-6">
              {user ? (
                <>
                  <img
                    src={user.avatar ?? userAvatar}
                    alt="User Avatar"
                    className="w-20 h-20 rounded-full border-2 border-gray-300"
                  />
                  <div>
                    <h2 className="text-xl font-semibold text-gray-700">{user.name}</h2>
                    <p className="text-gray-500">{user.role ?? "Admin"}</p>
                    <p className="text-gray-500">Joined on {user.created_at}</p>
                    <p className="text-gray-500">{user.email}</p>
                  </div>
                </>
              ) : (
                <p>Loading profile...</p>
              )}
            </section>

            {/* Widgets Section */}
            <section className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
              <DashboardWidgets
                title="Total Users"
                count="1,240"
                icon="users"
                bgColor="bg-blue-600"
                data-aos="fade-up"
              />
              <DashboardWidgets
                title="Active Requests"
                count="320"
                icon="tasks"
                bgColor="bg-green-600"
                data-aos="fade-up"
                data-aos-delay="100"
              />
              <DashboardWidgets
                title="Revenue"
                count="$15,300"
                icon="dollar-sign"
                bgColor="bg-yellow-600"
                data-aos="fade-up"
                data-aos-delay="200"
              />
              <DashboardWidgets
                title="Pending Transactions"
                count="25"
                icon="clock"
                bgColor="bg-red-600"
                data-aos="fade-up"
                data-aos-delay="300"
              />
            </section>

            {/* Charts Section */}
            <section className="grid grid-cols-1 lg:grid-cols-2 gap-6">
              <div className="bg-white shadow-lg rounded-lg p-6" data-aos="fade-left">
                <h2 className="text-xl font-semibold text-gray-700 mb-4">Requests Over Time</h2>
                <BarChart />
              </div>
              <div className="bg-white shadow-lg rounded-lg p-6" data-aos="fade-right">
                <h2 className="text-xl font-semibold text-gray-700 mb-4">Revenue Trends</h2>
                <LineChart />
              </div>
            </section>

            {/* Recent Data Sections */}
            <section className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-5">
              <div className="bg-white shadow-lg rounded-lg p-6" data-aos="fade-up">
                <RecentRequests />
              </div>
              <div className="bg-white shadow-lg rounded-lg p-6" data-aos="fade-up" data-aos-delay="100">
                <RecentTransactions />
              </div>
            </section>

            {/* Additional Sections */}
            <section className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-8">
              <ActivityLog />
              <UserGrowthChart />
            </section>
            <section className="grid grid-cols-1 gap-6 mt-8">
              <ServerStatus />
            </section>
          </main>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Dashboard;
