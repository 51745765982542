import axios from 'axios';

const BASE_URL = 'https://api.isendxpress.com/api/users'; // Replace with your API base URL


// Helper function to get token from localStorage
const getAuthToken = () => {
  const token = localStorage.getItem('auth_token');
  return token ? `Bearer ${token}` : '';
};

const userService = {
  getAllUsers: async (filters = {}) => {
    try {
      const response = await axios.get(BASE_URL, {
        params: filters,
        headers: { Authorization: getAuthToken() },
      });
      console.log(response.data)
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  },

  getUserById: async (id) => {
    try {
      const response = await axios.get(`${BASE_URL}/${id}`, {
        headers: { Authorization: getAuthToken() },
      });
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  },

  createUser: async (data) => {
    try {
      const response = await axios.post(BASE_URL, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: getAuthToken(),
        },
      });
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  },

  updateUser: async (id, data) => {
    try {
      const response = await axios.put(`${BASE_URL}/${id}`, data, {
        headers: { Authorization: getAuthToken() },
      });
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  },

  deleteUser: async (id) => {
    try {
      const response = await axios.delete(`${BASE_URL}/${id}`, {
        headers: { Authorization: getAuthToken() },
      });
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  },


};

export default userService;
