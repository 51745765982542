import React, { useState, useEffect } from "react";
import {
  FiSearch,
  FiEdit,
  FiTrash2,
  FiEye,
  FiPackage,
  FiPlus,
  FiLoader,  // Add FiLoader for the loading spinner
} from "react-icons/fi"; 
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import couponService from "../services/couponService";
import { useNavigate } from "react-router-dom";

const Coupons = () => {
  const [coupons, setCoupons] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [loading, setLoading] = useState(false);  // Loading state for fetching
  const [submitting, setSubmitting] = useState(false);  // Loading state for submitting

  const navigate = useNavigate();

  // Function to get coupons data
  const getCoupons = async () => {
    setLoading(true);  // Set loading to true while fetching
    try {
      const data = await couponService.getAllCoupons();
      setCoupons(data);
    } catch (error) {
      console.error("Failed to fetch coupons:", error);
    } finally {
      setLoading(false);  // Set loading to false once data is fetched
    }
  };

  useEffect(() => {
    getCoupons(); // Fetch coupons when the component mounts
  }, []);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleStatusFilter = (e) => {
    setSelectedStatus(e.target.value);
  };

  const filteredCoupons = coupons
    .filter((coupon) =>
      coupon.code.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .filter((coupon) => {
      if (selectedStatus) {
        return coupon.status === selectedStatus;
      }
      return true;
    });

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleAddCoupon = async () => {
    setSubmitting(true);  // Show loading icon when submitting
    try {
      // Your logic to handle adding a coupon (e.g., navigate to the add page)
      navigate("/coupon/add");
    } finally {
      setSubmitting(false);  // Hide the loading icon after submission
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <div className="flex flex-1">
        <Sidebar isOpen={isSidebarOpen} />
        <div
          className={`flex-1 ${
            isSidebarOpen ? "pl-64" : "pl-16"
          } transition-all`}
        >
          <Header toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} />

          <main className="p-6">
            <h1 className="text-3xl font-semibold text-gray-700 mb-6">
              Coupons
            </h1>

            {/* Add Coupon Button */}
            <div className="mb-6">
              <button
                onClick={handleAddCoupon}
                className="px-4 py-2 bg-green-500 text-white rounded-lg flex items-center space-x-2 hover:bg-green-600"
                disabled={submitting} // Disable button when submitting
              >
                {submitting ? (
                  <FiLoader className="animate-spin" size={18} />
                ) : (
                  <>
                    <FiPlus size={18} />
                    <span>Add Coupon</span>
                  </>
                )}
              </button>
            </div>

            <div className="flex items-center mb-6 space-x-4">
              <div className="relative w-full md:w-1/3">
                <input
                  type="text"
                  className="w-full pl-10 pr-4 py-2 border border-green-400 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500 bg-white"
                  placeholder="Search Coupons"
                  value={searchQuery}
                  onChange={handleSearch}
                />
                <FiSearch
                  className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500"
                  size={18}
                />
              </div>

              <div className="relative w-full md:w-1/4">
                <select
                  className="w-full py-2 pl-3 pr-10 border border-green-400 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500 bg-white"
                  value={selectedStatus}
                  onChange={handleStatusFilter}
                >
                  <option value="">All Statuses</option>
                  <option value="Active">Active</option>
                  <option value="Expired">Expired</option>
                </select>
              </div>
            </div>

            {/* Show Loading Icon while fetching data */}
            {loading ? (
              <div className="flex justify-center items-center p-6 bg-white rounded-lg shadow-lg">
                <FiLoader className="animate-spin text-gray-500" size={50} />
                <p className="ml-4 text-gray-600">Loading...</p>
              </div>
            ) : filteredCoupons.length === 0 ? (
              <div className="flex justify-center items-center p-6 bg-white rounded-lg shadow-lg">
                <FiPackage size={50} className="text-gray-400" />
                <p className="ml-4 text-gray-600">No coupons yet</p>
              </div>
            ) : (
              <div className="overflow-x-auto bg-white shadow-lg rounded-lg">
                <table className="w-full table-auto">
                  <thead className="bg-green-50 text-green-600">
                    <tr>
                      <th className="py-3 px-6 text-left">Code</th>
                      <th className="py-3 px-6 text-left">Discount</th>
                      <th className="py-3 px-6 text-left">Status</th>
                      <th className="py-3 px-6 text-center">Actions</th>
                    </tr>
                  </thead>
                  <tbody className="text-gray-700">
                    {filteredCoupons.map((coupon, index) => (
                      <tr
                        key={coupon.id}
                        className={`border-t ${
                          index % 2 === 0 ? "bg-gray-50" : "bg-white"
                        }`}
                      >
                        <td className="py-3 px-6">{coupon.code}</td>
                        <td className="py-3 px-6">{coupon.discount_value}</td>
                        <td className="py-3 px-6">
                          {coupon.is_active ? (
                            <span className="bg-green-500 text-white py-1 px-3 rounded-full text-sm">
                              Active
                            </span>
                          ) : (
                            <span className="bg-yellow-500 text-white py-1 px-3 rounded-full text-sm">
                              Not Active
                            </span>
                          )}
                        </td>
                        <td className="py-3 px-6 text-center">
                          <button className="text-green-500 hover:text-green-700 mr-2">
                            <FiEye size={18} />
                          </button>
                          <button className="text-yellow-500 hover:text-yellow-700 mr-2">
                            <FiEdit size={18} />
                          </button>
                          <button className="text-red-500 hover:text-red-700">
                            <FiTrash2 size={18} />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </main>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Coupons;
