import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';
import Users from '../pages/Users';
import NotFound from '../pages/NotFound';
import Contacts from '../pages/Contact';
import PickupRequests from '../pages/PickupRequests';
import Settings from '../pages/Settings';
import AuthService from '../services/AuthService';
import Coupons from '../pages/Coupon';
import AddCouponPage from '../pages/AddCouponPage';


const ProtectedRoute = ({ element }) => {
  return AuthService.isAdmin() ? element : <Navigate to="/" replace state={{ message: 'You may not have the right roles.' }} />;
};

const AppRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/dashboard" element={<ProtectedRoute element={<Dashboard />} />} />
      <Route path="/users" element={<ProtectedRoute element={<Users />} />} />
      <Route path="/contacts" element={<ProtectedRoute element={<Contacts />} />} />
      <Route path="/pickup-requests" element={<ProtectedRoute element={<PickupRequests />} />} />
      <Route path="/settings" element={<ProtectedRoute element={<Settings />} />} />
      <Route path="/coupons" element={<ProtectedRoute element={<Coupons />} />} />
      <Route path="/coupon/add" element={<ProtectedRoute element={<AddCouponPage />} />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRouter;
