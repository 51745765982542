import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Updated import for useNavigate
import { FiLoader, FiSave, FiX } from 'react-icons/fi'; // For save and close actions
import couponService from '../services/couponService'; // Assuming couponService is implemented correctly
import Footer from '../components/Footer';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';

const AddCouponPage = () => {
  const navigate = useNavigate(); // Using useNavigate instead of useHistory
  const [couponData, setCouponData] = useState({
    code: '',
    discount_type: 'percentage', // Default discount type
    discount_value: '',
    min_order_value: '',
    max_discount: '',
    usage_limit: '',
    times_used: 0,
    is_active: true, // Default status is active
    start_date: '',
    end_date: '',
  });

  const [errorMessages, setErrorMessages] = useState({
    code: '',
    discount_value: '',
    min_order_value: '',
    max_discount: '',
    usage_limit: '',
    start_date: '',
    end_date: '',
  });

  const [loading, setLoading] = useState(false); // Loading state to control the loading spinner

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCouponData({
      ...couponData,
      [name]: value,
    });
  };

  // Form submission handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessages({
      code: '',
      discount_value: '',
      min_order_value: '',
      max_discount: '',
      usage_limit: '',
      start_date: '',
      end_date: '',
    });

    let valid = true;
    // Validation logic
    if (!couponData.code) {
      setErrorMessages((prev) => ({ ...prev, code: 'Coupon code is required' }));
      valid = false;
    }
    if (!couponData.discount_value || isNaN(couponData.discount_value)) {
      setErrorMessages((prev) => ({
        ...prev,
        discount_value: 'Discount value should be a valid number',
      }));
      valid = false;
    }
    if (!couponData.min_order_value || isNaN(couponData.min_order_value)) {
      setErrorMessages((prev) => ({
        ...prev,
        min_order_value: 'Minimum order value should be a valid number',
      }));
      valid = false;
    }
    if (!couponData.max_discount || isNaN(couponData.max_discount)) {
      setErrorMessages((prev) => ({
        ...prev,
        max_discount: 'Maximum discount should be a valid number',
      }));
      valid = false;
    }
    if (!couponData.usage_limit || isNaN(couponData.usage_limit)) {
      setErrorMessages((prev) => ({
        ...prev,
        usage_limit: 'Usage limit should be a valid number',
      }));
      valid = false;
    }
    if (!couponData.start_date) {
      setErrorMessages((prev) => ({
        ...prev,
        start_date: 'Start date is required',
      }));
      valid = false;
    }
    if (!couponData.end_date) {
      setErrorMessages((prev) => ({
        ...prev,
        end_date: 'End date is required',
      }));
      valid = false;
    }

    if (valid) {
      setLoading(true); // Set loading to true when form submission starts
      try {
        await couponService.createCoupon(couponData); // Assuming couponService.createCoupon is defined
        navigate('/coupons'); // Use navigate to redirect to coupons list page
      } catch (error) {
        console.error('Failed to create coupon:', error);
      } finally {
        setLoading(false); // Set loading to false after the coupon is created or error occurs
      }
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <div className="flex flex-1">
        <Sidebar isOpen={true} />
        <div className="flex-1 pl-64 transition-all">
          <Header />

          <main className="p-6">
            <h1 className="text-3xl font-semibold text-gray-700 mb-6">Add Coupon</h1>

            {/* Add Coupon Form */}
            <form onSubmit={handleSubmit} className="bg-white p-6 rounded-lg shadow-lg">
              <div className="mb-4">
                <label htmlFor="code" className="block text-sm font-medium text-gray-700">
                  Coupon Code
                </label>
                <input
                  type="text"
                  id="code"
                  name="code"
                  value={couponData.code}
                  onChange={handleInputChange}
                  className="w-full mt-2 px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter coupon code"
                />
                {errorMessages.code && <p className="text-red-500 text-sm">{errorMessages.code}</p>}
              </div>

              <div className="mb-4">
                <label htmlFor="discount_value" className="block text-sm font-medium text-gray-700">
                  Discount Value
                </label>
                <input
                  type="number"
                  id="discount_value"
                  name="discount_value"
                  value={couponData.discount_value}
                  onChange={handleInputChange}
                  className="w-full mt-2 px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
                  placeholder="Enter discount value"
                />
                {errorMessages.discount_value && <p className="text-red-500 text-sm">{errorMessages.discount_value}</p>}
              </div>

              <div className="mb-4">
                <label htmlFor="min_order_value" className="block text-sm font-medium text-gray-700">
                  Minimum Order Value
                </label>
                <input
                  type="number"
                  id="min_order_value"
                  name="min_order_value"
                  value={couponData.min_order_value}
                  onChange={handleInputChange}
                  className="w-full mt-2 px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
                  placeholder="Enter minimum order value"
                />
                {errorMessages.min_order_value && <p className="text-red-500 text-sm">{errorMessages.min_order_value}</p>}
              </div>

              <div className="mb-4">
                <label htmlFor="max_discount" className="block text-sm font-medium text-gray-700">
                  Maximum Discount
                </label>
                <input
                  type="number"
                  id="max_discount"
                  name="max_discount"
                  value={couponData.max_discount}
                  onChange={handleInputChange}
                  className="w-full mt-2 px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
                  placeholder="Enter maximum discount"
                />
                {errorMessages.max_discount && <p className="text-red-500 text-sm">{errorMessages.max_discount}</p>}
              </div>

              <div className="mb-4">
                <label htmlFor="usage_limit" className="block text-sm font-medium text-gray-700">
                  Usage Limit
                </label>
                <input
                  type="number"
                  id="usage_limit"
                  name="usage_limit"
                  value={couponData.usage_limit}
                  onChange={handleInputChange}
                  className="w-full mt-2 px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
                  placeholder="Enter usage limit"
                />
                {errorMessages.usage_limit && <p className="text-red-500 text-sm">{errorMessages.usage_limit}</p>}
              </div>

              <div className="mb-4">
                <label htmlFor="start_date" className="block text-sm font-medium text-gray-700">
                  Start Date
                </label>
                <input
                  type="date"
                  id="start_date"
                  name="start_date"
                  value={couponData.start_date}
                  onChange={handleInputChange}
                  className="w-full mt-2 px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
                />
                {errorMessages.start_date && <p className="text-red-500 text-sm">{errorMessages.start_date}</p>}
              </div>

              <div className="mb-4">
                <label htmlFor="end_date" className="block text-sm font-medium text-gray-700">
                  End Date
                </label>
                <input
                  type="date"
                  id="end_date"
                  name="end_date"
                  value={couponData.end_date}
                  onChange={handleInputChange}
                  className="w-full mt-2 px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
                />
                {errorMessages.end_date && <p className="text-red-500 text-sm">{errorMessages.end_date}</p>}
              </div>

              <div className="flex items-center justify-between">
                <button
                  type="button"
                  className="bg-gray-500 text-white px-4 py-2 rounded-md"
                  onClick={() => navigate('/coupons')}
                >
                  <FiX className="inline mr-2" />
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-green-500 text-white px-4 py-2 rounded-md"
                  disabled={loading} // Disable the button while loading
                >
                  {loading ? (
                    <FiLoader size={24} color="#fff" /> // Show the spinner when loading
                  ) : (
                    <>
                      <FiSave className="inline mr-2" />
                      Save Coupon
                    </>
                  )}
                </button>
              </div>
            </form>
          </main>

          <Footer />
        </div>
      </div>
    </div>
  );
};

export default AddCouponPage;
